<template>
  <div class="app flex-row align-items-center">
    <div class="container mt-3">
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-5 display-mobile">
          <img style="height: 75%"
            src="../../assets/img/design.png"
            alt="admin@bootstrapmaster.com"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
              <b-card-body class="background-mobile">
                <b-row align-v="center">
                  <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                  </b-col>
                </b-row>
                <b-form @submit.prevent="actionForget">
                  <h1 class="h2 text-center"> Forgot Password? </h1>
                  <b-form-group label="Enter the email address that you have registered on the gramedia digital website and we will send you the password reset step in the email." class="mt-5 mb-5">
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="email"
                      autocomplete="email"
                      v-model="Form.email"
                      required
                    />
                  </b-form-group>
                  
                  <b-row>
                    <b-col>
                      <b-button
                        :disabled="isDisable"
                        lg="4"
                        type="submit"
                        variant="primary"
                        class="px-4 w-100"
                      >
                        Reset
                       <i class="fa fa-sign-in" aria-hidden="true"></i>
                        <b-spinner v-if="!isDisable && isLoading" type="grow" label="Spinning"></b-spinner>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <br/>
                      <label>Already Have Account?</label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <router-link :to="{ path: '/'}">
                        <b-button variant="link" class="px-0 mb-5"
                          > Sign In
                        </b-button>
                      </router-link>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "forgotPassword",
  data() {
    return {
      Form: {},
      isActive: false,
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.forgetPassword.isSuccess,
      message: (state) => state.forgetPassword.successMessage,
      isLoading: (state) => state.forgetPassword.isLoading,
      statusCode: (state) => state.forgetPassword.statusCode,
      isError:(state) => state.forgetPassword.isError,
      
    }),
    isDisable() {
      return !(this.Form.email && this.Form.email.length > 4);
    }
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Forgot Password | Content Management System Ebooks Gramedia.com';
        }
    },
    success: function () {
      if(this.success){
        this.$swal
        .fire({
          title: `Email Send`,
          text: this.message,
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Close",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$router.go();
          }
        });
      }
    },
    isError : function (){
      if(this.isError){
        this.messageAlert("error",this.message)
      }
    }
  },
  methods: {
    ...mapActions("forgetPassword", ["forgetPasswordAdapter"]),
    actionForget() {
       let payload = {
        email: this.Form.email,
      };
      this.forgetPasswordAdapter(payload)
    },messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
  mounted() {},
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}

</style>
