var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('b-row', {
    staticClass: "row justify-content-center"
  }, [_c('b-col', {
    staticClass: "left mt-5 display-mobile",
    attrs: {
      "md": "6",
      "sm": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "75%"
    },
    attrs: {
      "src": require("../../assets/img/design.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('b-col', {
    staticClass: "right",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-group', [_c('b-card-body', {
    staticClass: "background-mobile"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "m-5",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })])], 1), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.actionForget.apply(null, arguments);
      }
    }
  }, [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v(" Forgot Password? ")]), _c('b-form-group', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "label": "Enter the email address that you have registered on the gramedia digital website and we will send you the password reset step in the email."
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "email",
      "autocomplete": "email",
      "required": ""
    },
    model: {
      value: _vm.Form.email,
      callback: function ($$v) {
        _vm.$set(_vm.Form, "email", $$v);
      },
      expression: "Form.email"
    }
  })], 1), _c('b-row', [_c('b-col', [_c('b-button', {
    staticClass: "px-4 w-100",
    attrs: {
      "disabled": _vm.isDisable,
      "lg": "4",
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Reset "), _c('i', {
    staticClass: "fa fa-sign-in",
    attrs: {
      "aria-hidden": "true"
    }
  }), !_vm.isDisable && _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('br'), _c('label', [_vm._v("Already Have Account?")])])], 1), _c('b-row', [_c('b-col', [_c('router-link', {
    attrs: {
      "to": {
        path: '/'
      }
    }
  }, [_c('b-button', {
    staticClass: "px-0 mb-5",
    attrs: {
      "variant": "link"
    }
  }, [_vm._v(" Sign In ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }